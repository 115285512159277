<template>
  <div class="my">
    <el-row class="tac">
      <el-col :span="5">
        <el-menu
          :default-active="myActiveIndex"
          class="el-menu-vertical-demo mytabBox"
          router
        >
          <el-menu-item class="mytabItem" index="myCollection">
            <i class="el-icon-star-off"></i>
            <span slot="title">{{myData.favorites}}</span>
          </el-menu-item>
          <el-menu-item class="mytabItem" index="myComments">
            <i class="el-icon-chat-dot-square"></i>
            <span slot="title">{{myData.comment}}</span>
          </el-menu-item>
          <el-menu-item class="mytabItem" index="myMessage">
            <i class="el-icon-star-off"></i>
            <span slot="title">{{myData.message}}</span>
          </el-menu-item>
          <el-menu-item class="mytabItem" index="changePswd">
            <i class="el-icon-unlock"></i>
            <span slot="title">{{myData.password}}</span>
          </el-menu-item>
          <el-menu-item class="mytabItem" index="mySubmit">
            <i class="el-icon-tickets"></i>
            <span slot="title">{{myData.submit}}</span>
          </el-menu-item>
          <el-menu-item class="mytabItem" index="out"  @click="goPage('out')">
            <i class="el-icon-switch-button"></i>
            <span slot="title">{{myData.signout}}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="19">
        <div>
          <router-view></router-view>
          <!-- <router-view name="mySublist"></router-view> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "my",
  data() {
    return {};
  },
  created() {
    this.$store.state.activeIndex = "";
    this.$store.state.myActiveIndex = this.$route.path;
  },
  methods: {
    // navmenu router动态选中
    handleSelect(index) {
      this.$store.state.myActiveIndex = index;
      //按钮选中之后设置当前的index为store里的值。
    },
    async goPage(path, auth) {
      if (auth && !this.vuex_token) {
        this.$u.toast("请先登录再操作！");
        return;
      }
      if (path == "out") {
        let res = await this.$api.https.goUserLogout();
        if (!res.code) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "warning"
          });
          return;
        }
        //退出成功
        this.$store.state.vuex_user = {
          avatar: ""
        }; //清除vuex_user
        this.$store.state.vuex_token = ""; //清除用户token
        localStorage.setItem('lifeData','')
        this.$message({
            showClose: true,
            message: "退出登录成功",
            type: "success"
          });
        setTimeout(() => {
          this.$router.replace({ path: "/" });
        }, 1500);
        return;
      }
    }
  },
  computed: {
    myActiveIndex() {
      return this.$store.state.myActiveIndex;
    },
    myData() {
      return this.i18n["my"];
    }
  },
  watch: {
    // 监测store.state
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #0b6fbc;
.my {
  background-color: #ffffff;
  line-height: 24px;
  text-align: left;
  padding: 0px 40px 50px;
  .mytabBox {
    box-shadow: 4px 4px 10px #00000010;
    border: 1px solid #cbe8ff;
    .mytabItem {
      padding: 10px !important;
      //   margin: 0 20px;
      font-size: 14px;
      //   border-bottom: 1px solid #cbe8ff;
      display: flex;
      align-items: center;
      i {
        font-size: 24px;
        margin-right: 10px;
      }
    }
    .mytabItem:last-child {
      border-bottom: none;
    }
    .is-active {
      border-right: 7px solid $vue_color;
      color: $vue_color;
    }
  }
}
</style>
